<template>
  <div class="recharge wrap">
    <!-- 导航栏 -->
    <van-nav-bar
      :title="$t('newAdd.Recharge')"
      left-arrow
      @click-left="$router.go(-1)"
    >
      <template #right>
        <button
          @click="$router.push('/ThirdRechargeList')"
          style="
            padding: calc(6rem / 16) calc(12rem / 16);
            background: #fff;
            color: var(--theme);
            border-radius: 4px;
            font-size: calc(14rem / 16);
          "
        >
          {{ $t("newAdd.Record") }}
        </button>
        <!-- <button @click="handlerService">Service</button> -->
      </template>
    </van-nav-bar>
    <!-- 商品信息 -->
    <div class="goodInfo_wrap">
      <img :src="goodInfo.goods_image" alt="" class="good_pic" />
      <div class="other_info">
        <div class="good_id" v-if="!verConfig.hasGiftRate">
          {{ `ID: ${goodInfo.id}` }}
        </div>
        <div class="good_title">
          {{ goodInfo.goods_name || "" }}
        </div>
        <div class="amount" v-if="!verConfig.hasGiftRate">
          {{ `${$t("recharge.amount")}: ` }}
          <span>{{ $money(goodInfo.goods_price) }}</span>
        </div>
      </div>
    </div>
    <div class="money_info" v-if="verConfig.hasGiftRate">
      <div class="good_id">
        {{ `ID: ` }}
        <span>{{ goodInfo.order_no }}</span>
      </div>
      <div class="blind_box_income">
        {{ `${$t("recharge.bonusRatio")}: ` }}
        <span>{{ `10% - 50%` }}</span>
      </div>
      <div class="blind_box_income">
        {{ `${$t("recharge.blindBoxIncome")}: ` }}
        <span>{{ blindBoxIncome }}</span>
      </div>
      <div class="amount">
        {{ `${$t("recharge.amount")}: ` }}
        <span>{{ $money(goodInfo.goods_price) }}</span>
      </div>
    </div>
    <div class="bg_fff">
      <!-- language-ch 充值账户：￥ -->
      <van-field
        center
        v-model="account"
        disabled
        :label="$t('newAdd.MemberAccount')"
        :placeholder="$t('newAdd.AccountPlaceholder')"
      />
      <!-- language-ch 充值金额 -->
      <!-- 允许输入数字，调起带符号的纯数字键盘 -->
      <van-field
        center
        class="number"
        disabled
        v-model="number"
        type="number"
        :label="`${$t('newAdd.RechargeAmount')}\n`"
        :placeholder="$t('newAdd.RechargeAmountPlaceholder')"
      />
    </div>

    <!-- <van-grid :gutter="10" class="grid">
      <van-grid-item
        class="grid_item"
        v-for="value in countList"
        :key="value"
        @click="addClass(value)"
      >
        <div slot="text" :class="{ active: number === value }">
          {{$money(value,verConfig.moneyType)}}
        </div>
      </van-grid-item>
    </van-grid> -->

    <van-cell title="单元格">
      <!-- language-ch 选择充值方式 -->
      <span slot="title" class="list-title-text">{{
        $t("newAdd.SelectRechargeMethod")
      }}</span>
      <!-- language-ch 充值教程 -->
      <!-- <span slot="default" class="list-title-rtext" @click="showTutorial = true"
        >Top-up tutorial</span
      > -->
    </van-cell>

    <div class="tutorial" v-show="showTutorial">
      <img
        :src="require('@/assets/images/user_pay/step/' + addCount + '.jpg')"
        @click="changeImg"
      />
    </div>

    <!-- 标签页 支付方式-->
    <van-tabs
      v-model="active"
      swipeable
      class="tabs"
      line-height="2px"
      line-width="15px"
    >
      <van-tab v-for="item in tabPages" :key="item.type">
        <span slot="title">{{ item.title }}</span>
        <div v-if="item.lists.length > 0">
          <div
            class="mode"
            v-for="value in item.lists"
            :key="value.id"
            @click="paySubmit(value)"
          >
            <img
              v-if="item.type !== 'idpay'"
              class="step-image"
              :src="require(`@/assets/images/user_pay/${item.type}.png`)"
            />
            <!-- <img
              v-else-if="item.type !== 'idpay' && ver === 'myntra'"
              class="step-image"
              :src="require(`@/assets/images/user_pay/paytm.png`)"
            /> -->
            <div class="step">
              <h5>{{ value.title }}</h5>
              <div class="desc_wrap">
                <p>{{ value.desc }}</p>
                <img :src="require('@/assets/icon/enter.png')" alt="" />
              </div>
            </div>
            <img
              v-if="value.letter == 1"
              class="tabbar-letter"
              :src="require('@/assets/images/user_pay/letter.png')"
            />
          </div>
        </div>
        <div v-else class="tips">
          {{ $t("newAdd.HaveNoteMethod") }}
        </div>
      </van-tab>
    </van-tabs>
    <!-- <Tabbar :active="rechargePath" /> -->
  </div>
</template>

<script>
import {
  GET_CONFIG_INFO,
  GET_RECHARGE_LIST,
  getUserInfoNew,
  RECHARGE_COMMIT,
} from "@/api";
import Cookies from "js-cookie";
import { getUserInfo } from "@/utils/tools";
import Tabbar from "@/components/Tabbar";
import verConfig from "@/utils/verConfig";
import {
  NavBar,
  cell,
  icon,
  field,
  Dialog,
  Toast,
  Grid,
  GridItem,
  tabs,
  tab,
} from "vant";
import { mapGetters } from "vuex";

export default {
  components: {
    [NavBar.name]: NavBar,
    [cell.name]: cell,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [tabs.name]: tabs,
    [tab.name]: tab,
    [Dialog.Component.name]: Dialog.Component,
    Tabbar,
  },
  data() {
    return {
      verConfig,
      countList: [
        "100",
        "200",
        "500",
        "1000",
        "5000",
        "10000",
        "30000",
        "50000",
      ],
      // account: "",
      // number: "",
      active: 0,
      tabPages: [],
      showTutorial: false,
      count: 1,
      serviceURL: "",
      country: Cookies.get("language"),
      goodInfo: {},
      rechargeType: "",
      rechargePath: this.verConfig.pathNewRecharge
        ? "third-recharge-n"
        : "new-recharge",
    };
  },
  created() {
    // getUserInfoNew();
    this.getRechargeList();
    // this.getLists()
    // this.getService()
    // console.log(Cookies.get('language'));
  },
  mounted() {
    // this.account = getUserInfo().userphone;
    const data = JSON.parse(localStorage.getItem("rechargeGoodDataInfo"));
    // this.goodInfo = JSON.parse(this.$route.query.data);
    this.goodInfo = data.data;
    this.rechargeType = data.type;
    // this.number = this.goodInfo.goods_price;
  },
  methods: {
    // add
    goback() {
      history.back();
    },
    getRechargeList() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_RECHARGE_LIST()
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            this.tabPages = r.data.data;
          }
        })
        .catch((e) => {
          Toast.clear();
        });
    },
    // 将点击项的值绑定给充值金额框
    addClass(value) {
      // this.number = value;
    },

    // 获取充值方式的数据
    async getLists() {
      const {
        data: { data, ret },
      } = await request({
        method: "get",
        url: "pay/lists",
      });
      if (ret === 1) {
        this.tabPages = data;
      }
    },

    // async getService () {
    //   const { data: { data, ret } } = await request({
    //     method: 'get',
    //     url: 'other/config',
    //     params: {
    //       act: 'system',
    //       refresh: this.isLoading === true ? 1 : 0
    //     }
    //   })
    //   if (ret === 1) {
    //     this.serviceURL = data.service_url
    //   }
    // },
    // 教程
    changeImg() {
      this.count += 1;
      if (this.count === 6) {
        this.showTutorial = false;
        this.count = 1;
      }
    },

    handlerService() {
      //   Toast({
      //     message: 'Try again later'
      //   })
      window.location.href = this.serviceURL;
    },

    paySubmit(item) {
      // console.log(item);
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      RECHARGE_COMMIT({
        id: item.id,
        money: this.goodInfo.goods_price,
        // account: this.account,
        third_id:
          this.rechargeType !== "third"
            ? Number(this.goodInfo.id) + 100000000
            : this.goodInfo.id,
        // isbefore: 1,
      })
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 2) {
            // const res = {...r.data.data, ...r.data.data.setting}
            Toast.clear();
            window.location.href = r.data.data.url;
          } else if (r.data.ret === 1) {
            if (r.data.data.bank.indexOf("qrcode") != -1) {
              r.data.data.step = item.step;
              r.data.data.id = item.id;
              this.$router.push({
                name: "qrPay",
                params: {
                  payData: r.data.data,
                },
              });
            }
          } else if (r.data.ret === 3) {
            if (r.data.data.bank.indexOf("bank-BANK") != -1) {
              r.data.data.step = item.step;
              r.data.data.id = item.id;
              this.$router.push({
                name: "bankPay",
                params: {
                  payData: r.data.data,
                },
              });
            }
          } else {
            // Toast('System exception, please try again later')
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
  },
  beforeDestroy() {
    // localStorage.removeItem("rechargeGoodDataInfo")
  },
  computed: {
    ...mapGetters(["userInfo"]),
    number: {
      set(val) {
        return val;
      },
      get() {
        return this.$money(this.goodInfo.goods_price, false);
      },
    },
    account() {
      return (this.userInfo || {}).userphone || (this.userInfo || {}).email;
    },
    addCount() {
      return this.count;
    },
    blindBoxIncome() {
      return `${this.$money(
        (this.goodInfo.goods_price * (10 / 100)).toFixed(2)
      )} - ${this.$money((this.goodInfo.goods_price * (50 / 100)).toFixed(2))}`;
      // return this.$money(
      //   this.goodInfo.goods_price * (this.goodInfo.gift_rate / 100)
      // );
    },
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
.recharge {
  position: relative;
  // font-family: "PingFang-Regular";
  height: 100%;
  /deep/ .van-tabs__line {
    background-color: @Color;
  }
  .goodInfo_wrap {
    // margin: 6px 0;
    padding: 6px 10px;
    padding-top: 10px;
    display: flex;
    background: #fff;
    .good_pic {
      width: 100px;
      height: 100px;
    }
    .other_info {
      margin-left: 20px;
      display: flex;
      min-height: 80px;
      flex-direction: column;
      // justify-content: space-between;
      font-size: calc(14rem / 16);
      > div {
        line-height: 1.5;
        > span {
          color: var(--theme);
        }
      }
    }
  }
  .money_info {
    padding: 0 10px;
    padding-bottom: 10px;
    line-height: 1.5;
    color: #757c90;
    font-size: calc(14rem / 16);
    background: #fff;
    span {
      color: #3f79ff;
      // font-size: calc(16rem / 16);
    }
  }
  .bg_fff {
    background: #fff;
    /deep/ .van-field__control:disabled {
      color: #333;
      -webkit-text-fill-color: #333;
    }
  }
  .van-nav-bar {
    background-color: @Color;
    background-image: var(--nav-background);

    /deep/ .van-nav-bar__content {
      // background-color: @Color;
    }
    /deep/.van-nav-bar__title {
      color: #fff;
    }
    button {
      background-color: @Color;
      border: none;
      color: #fff;
      font-size: calc(10rem / 16);
    }
    .iconfont {
      font-size: calc(22rem / 16);
      color: #fff;
    }
  }

  .van-field {
    margin: calc(6rem / 16) 0;
    /deep/.van-field__label {
      width: calc(177rem / 16) !important;
    }
    /deep/.van-field__body {
      input {
        text-align: center;
      }
    }
  }
  .number {
    /deep/.van-field__body {
      input {
        text-align: center;
        color: @Color;
      }
    }
  }

  .grid {
    margin: calc(7.5rem / 16) 0;
    .grid_item {
      /deep/ .van-grid-item__content {
        padding: 0;
        div {
          width: 100%;
          height: calc(41rem / 16);
          font-size: calc(13rem / 16);
          line-height: calc(45rem / 16);
          text-align: center;
          color: @Color;
          background-color: #ffffff;
        }
        .active {
          color: #ffffff;
          background-color: @Color;
        }
      }
    }
  }

  .van-cell {
    background-color: transparent;
  }
  .list-title-text {
    font-size: calc(13rem / 16);
  }
  .list-title-rtext {
    color: #a87cf3;
    font-size: calc(13rem / 16);
    text-decoration: underline;
  }

  .tabs {
    .mode {
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      margin-top: calc(7.5rem / 16);
      padding: calc(7.5rem / 16);
      background-color: #fff;
    }
    .step {
      font-size: calc(12rem / 16);
      flex-grow: 1;
      h5 {
        margin: 0 0 calc(7.5rem / 16) 0;
      }
      .desc_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        img {
          height: 30px;
          min-width: 30px;
          margin-right: 20px;
        }
      }
      p {
        width: 80%;
      }
    }
    .tabbar-letter {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(32.5rem / 16);
      height: calc(32.5rem / 16);
    }
    .step-image {
      width: calc(50rem / 16);
      height: calc(50rem / 16);
      margin-right: calc(10rem / 16);
    }
    .tips {
      text-align: center;
      margin-top: calc(45rem / 16);
      font-size: calc(10rem / 16);
    }
  }

  .tutorial {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }
}
.backimg {
  width: 20px;
  height: 20px;
}
</style>
